<template>
  <div>ContentsError</div>
</template>

<script>
export default {
 name: "ContentsError",
}
</script>

<style>

</style>